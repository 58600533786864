<script>
import useVuelidate from '@vuelidate/core'
import {required, maxLength} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkCalendar from "@components/input/AkCalendar";
import AkDropdown from "@components/input/AkDropdown";
import AkInputText from "@components/input/AkInputText";
import AkInputTextArea from "@components/input/AkInputTextArea";
import AkInputNumber from "@components/input/AkInputNumber";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanFormationService from "@services/bilanFormationService";
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import rolePermissionService from "@services/rolePermissionService";
import affectationService from "@services/affectationService";

export default {
  components: {AkFormView, AkFormSubmitted, AkCalendar, AkDropdown, AkInputText, AkInputTextArea, AkInputNumber},
  mixins: [randomRef, roleMixin],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "bilan.create",
    }
  },
  data() {
    return {
      alreadyExists: false,
      submitted: false,
      agerList: [],
      uerList: [],
      ceiList: [],
      disableAger: null,
      disableUer: null,
      disableCei: null,
      current: {
        ceiId: null,
        uerId: null,
        agerId: null,
      }
    }
  },
  validations() {
    return {
      current: {
        agerId: {required},
        uerId: {required},
        ceiId: {required},
        materielCommentaire: {maxLength: maxLength(500)},
        circuitCommentaire: {maxLength: maxLength(500)},
        rivhCommentaire: {maxLength: maxLength(500)},
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
  
    let p4 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.formationReview.edit);

    let p5 = affectationService.findAllByUserId(this.currentUser.userId);
    p5.then(data => {
      this.affectations = data.affectations;
      this.agerList = data.agerList;
      if (this.agerList.length === 1) {
        this.current.agerId = this.agerList[0].id;
        this.disableAger = true;
      }

      this.uerList = data.uerList;
      if (this.uerList.length === 1) {
        this.current.uerId = this.uerList[0].id;
        this.disableUer = true;
      }

      this.ceiList = data.ceiList;
      if (this.ceiList.length === 1) {
        this.current.ceiId = this.ceiList[0].id;
        this.disableCei = true;
      }
    });

    Promise.all([p4, p5]).then(()=>{
      this.getRef().hideLoader();
    });
  },
  methods: {
    create() {
      if (!this.checkForm()) {
        this.getRef().error(this.$t("bilan.error.create"));
        return;
      }

      this.getRef().showLoader(this.$refs.form)
      bilanFormationService.create(this.current).then(data => {
        let msg = this.$t("bilan.added");
        this.$router.replace({ path: `/bilan/formation/${data.id}/details`, query: { msg: msg } });
      }).catch(e=>{
          this.getRef().hideLoader();
          this.getRef().error(this.$t("error."+e.response.data.error));
          this.submitted = false;
        }
      );
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error; 
    },
    testExistence() {
      this.getRef().resetMessages();
      if (this.current.ceiId) {
        bilanFormationService.findByCeiId(this.current.ceiId).then(data => {
          this.alreadyExists = !!data;
          if (this.alreadyExists) this.getRef().error("Ce bilan existe déjà pour ce CEI");
        });
      }
    }
  },
  computed: {
    uerListForCurrent() {
      return this.uerList.filter(uer => uer.agerId === this.current.agerId);
    },
    ceiListForCurrent() {
      return this.ceiList.filter(cei => cei.uerId === this.current.uerId);
    }
  },
  watch: {
    uerListForCurrent() {
      if(!this.uerListForCurrent.some(uer => uer.id===this.current.uerId))
        this.current.uerId = undefined;
    },
    ceiListForCurrent() {
      if(!this.ceiListForCurrent.some(cei => cei.id===this.current.ceiId))
        this.current.ceiId = undefined;
    },
    'current.ceiId'() {
      this.alreadyExists = false;
      this.testExistence();
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan.create')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
                      v-model="current.agerId"
                      :validator="v$.current.agerId"
                      :submitted=this.submitted
                      :options=this.agerList
                      :disabled=disableAger
                      option-value="id"
                      option-label="label"
                      class-name="col-md-4"/>
          <AkDropdown :label="$t('uer_label')"
                      v-if="current.agerId"
                      v-model="current.uerId"
                      :validator="v$.current.uerId"
                      :submitted=this.submitted
                      :options=this.uerListForCurrent
                      :disabled=disableUer
                      option-value="id"
                      option-label="label"
                      class-name="col-md-4"/>
          <AkDropdown :label="$t('cei_label')"
                      v-if="current.uerId"
                      v-model="current.ceiId"
                      :validator="v$.current.ceiId"
                      :submitted=this.submitted
                      :options=this.ceiListForCurrent
                      :disabled=disableCei
                      option-value="id"
                      option-label="label"
                      class-name="col-md-4"/>
        </div>
        
        <h5>{{ $t('bilan_formation.title1') }}</h5>

        <div class="form-row">
          <AkInputText
              v-model="current.gpsDiff"
              :validator="v$.current.gpsDiff"
              :submitted=this.submitted
              :label="$t('bilan_formation.gps_diff')"
              class-name="col-md-6"/>
          <AkInputText
              v-model="current.gpsForm"
              :validator="v$.current.gpsForm"
              :submitted=this.submitted
              :label="$t('bilan_formation.gps_form')"
              class-name="col-md-6"/>
        </div>

        <h5>{{ $t('bilan_formation.title2') }}</h5>

        <div class="form-row">
          <AkCalendar
              v-model="current.pevhDateParution"
              :validator="v$.current.pevhDateParution"
              :submitted=this.submitted
              :label="$t('bilan_formation.pevh_date_parution')"
              class-name="col-md-4"/>
          <AkCalendar
              v-model="current.pevhDateValidation"
              :validator="v$.current.pevhDateValidation"
              :submitted=this.submitted
              :label="$t('bilan_formation.pevh_date_validation')"
              class-name="col-md-4"/>
          <AkInputText
              v-model="current.pevhModeDiffusion"
              :validator="v$.current.pevhModeDiffusion"
              :submitted=this.submitted
              :label="$t('bilan_formation.pevh_mode_diffusion')"
              class-name="col-md-4"/>
        </div>
        
        <h5>{{ $t('bilan_formation.title3') }}</h5>

        <div class="form-row">
          <AkInputText
              v-model="current.materielForme"
              :validator="v$.current.materielForme"
              :submitted=this.submitted
              :label="$t('bilan_formation.materiel_forme')"
              class-name="col-md-6"/>
          <AkInputTextArea
              :rows=5
              v-model="current.materielCommentaire"
              :validator="v$.current.materielCommentaire"
              :submitted=this.submitted
              :label="$t('bilan_formation.materiel_commentaire')"
              class-name="col-md-6"/>
        </div>
        
        <h5>{{ $t('bilan_formation.title4') }}</h5>

        <div class="form-row">
          <AkInputText
              v-model="current.circuitForme"
              :validator="v$.current.circuitForme"
              :submitted=this.submitted
              :label="$t('bilan_formation.circuit_forme')"
              class-name="col-md-6"/>
          <AkInputTextArea
              :rows=5
              v-model="current.circuitCommentaire"
              :validator="v$.current.circuitCommentaire"
              :submitted=this.submitted
              :label="$t('bilan_formation.circuit_commentaire')"
              class-name="col-md-6"/>
        </div>
        
        <h5>{{ $t('bilan_formation.title5') }}</h5>

        <div class="form-row">
          <AkInputNumber
              v-model="current.rivhNb"
              :validator="v$.current.rivhNb"
              :submitted=this.submitted
              :label="$t('bilan_formation.rivh_nb')"
              class-name="col-md-6"/>
          <AkInputTextArea
              :rows=5
              v-model="current.rivhCommentaire"
              :validator="v$.current.rivhCommentaire"
              :submitted=this.submitted
              :label="$t('bilan_formation.rivh_commentaire')"
              class-name="col-md-6"/>
        </div>
        <div class="float-right">
          <button v-if="!alreadyExists && this.canCreateFormation()" @click=create() class="btn btn-primary" style="margin-left: 10px">{{ $t('save') }}</button>
        </div>
      </AkFormSubmitted>
    </template>
  </AkFormView>
</template>